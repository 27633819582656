/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import { Helmet } from "react-helmet"

function Seo({ pageData, authorName, location }) {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          wpStylesCSS
        }
      }
    `
  )

  // Prevent errors when generating CMS preview (which doesn't fetch all seo data)
  if (!pageData?.seo) {
    return (
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <style type="text/css">{wp.wpStylesCSS}</style>
      </Helmet>
    )
  }

  const {
    title,
    metaDesc,
    metaRobotsNoindex,
    metaRobotsNofollow,
    opengraphTitle,
    opengraphDescription,
    opengraphPublishedTime,
    opengraphModifiedTime,
    opengraphSiteName,
    opengraphType,
    readingTime,
  } = pageData.seo

  const { richSnippetSchemaJson } = pageData

  // build content for robots tag
  const robotsArray = []
  if (metaRobotsNoindex) robotsArray.push(metaRobotsNoindex)
  if (metaRobotsNofollow) robotsArray.push(metaRobotsNofollow)
  const robotsContent = !!robotsArray.length ? robotsArray.join(", ") : ""

  // build content for reading time
  const readingTimeContent = readingTime + " minutes"

  const baseUrl = "https://www.goodhire.com"
  const currentUrl = baseUrl + location.pathname // Using pathname instead of other properties since it's available at build time

  // build content for og:image tags
  const featuredImage = pageData.featuredImage?.node
  const featuredImageUrl = !!featuredImage
    ? baseUrl + featuredImage.localFile.publicURL
    : null
  const featuredImageWidth = !!featuredImage
    ? featuredImage.mediaDetails.width
    : null
  const featuredImageHeight = !!featuredImage
    ? featuredImage.mediaDetails.height
    : null

  const snippetScript = parse(richSnippetSchemaJson)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
        <meta name="robots" content={robotsContent} />
        <meta name="description" content={metaDesc} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content={opengraphType} />
        <meta property="og:title" content={opengraphTitle} />
        <meta property="og:description" content={opengraphDescription} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content={opengraphSiteName} />

        <meta property="og:image" content={featuredImageUrl} />
        <meta property="og:image:width" content={featuredImageWidth} />
        <meta property="og:image:height" content={featuredImageHeight} />

        <meta
          property="article:modified_time"
          content={opengraphModifiedTime}
        />
        <meta
          property="article:published_time"
          content={opengraphPublishedTime}
        />

        <link rel="canonical" href={currentUrl} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content={readingTimeContent} />
        <meta name="twitter:label2" content="Written By" />
        <meta name="twitter:data2" content={authorName} />

        <link
          rel="preload"
          href="/fonts/gotham/woff2/GothamSSm-Bold_Web-Latin.woff2"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/fonts/gotham/woff2/GothamSSm-Medium_Web-Latin.woff2"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/fonts/gotham/woff2/GothamSSm-Book_Web-Latin.woff2"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/fonts/source-sans/SourceSansPro-Bold-Latin.woff2"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/fonts/source-sans/SourceSansPro-Regular-Latin.woff2"
          as="font"
          crossOrigin="anonymous"
          type="font/woff2"
        />
        {/* Font face CSS inlined so gatsby won't create new font files, breaking the preloading */}
        <style type="text/css">
          {`
            @font-face {
              font-family: HCo Gotham SSm;
              src: url("/fonts/gotham/woff2/GothamSSm-Light_Web-Latin.woff2") format("woff2"),
                url("/fonts/gotham/woff/GothamSSm-Light_Web-Latin.woff") format("woff");
              font-weight: 300;
              font-style: normal;
              font-display: swap;
            }

            @font-face {
              font-family: HCo Gotham SSm;
              src: url("/fonts/gotham/woff2/GothamSSm-Book_Web-Latin.woff2") format("woff2"),
                url("/fonts/gotham/woff/GothamSSm-Book_Web-Latin.woff") format("woff");
              font-weight: 400;
              font-style: normal;
              font-display: swap;
            }

            @font-face {
              font-family: HCo Gotham SSm;
              src: url("/fonts/gotham/woff2/GothamSSm-Medium_Web-Latin.woff2") format("woff2"),
                url("/fonts/gotham/woff/GothamSSm-Medium_Web-Latin.woff") format("woff");
              font-weight: 500;
              font-style: normal;
              font-display: swap;
            }

            @font-face {
              font-family: HCo Gotham SSm;
              src: url("/fonts/gotham/woff2/GothamSSm-Bold_Web-Latin.woff2") format("woff2"),
                url("/fonts/gotham/woff/GothamSSm-Bold_Web-Latin.woff") format("woff");
              font-weight: 700;
              font-style: normal;
              font-display: swap;
            }

            @font-face {
              src: url("/fonts/source-sans/SourceSansPro-Light-Latin.woff2") format("woff2"),
                url("/fonts/source-sans/SourceSansPro-Light-Latin.woff") format("woff");
              font-family: Source Sans Pro;
              font-style: normal;
              font-weight: 300;
              font-display: swap;
            }

            @font-face {
              src: url("/fonts/source-sans/SourceSansPro-Regular-Latin.woff2") format("woff2"),
                url("/fonts/source-sans/SourceSansPro-Regular-Latin.woff") format("woff");
              font-family: Source Sans Pro;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
            }

            @font-face {
              src: url("/fonts/source-sans/SourceSansPro-Bold-Latin.woff2") format("woff2"),
                url("/fonts/source-sans/SourceSansPro-Bold-Latin.woff") format("woff");
              font-family: Source Sans Pro;
              font-style: normal;
              font-weight: 700;
              font-display: swap;
            }
            `}
        </style>

        <style type="text/css">{wp.wpStylesCSS}</style>
      </Helmet>

      {snippetScript}
    </>
  )
}

export default Seo
