import * as React from "react"
import { StoreContext } from "../../../store"

const Hamberger = () => {
  const { isMobileMenuActive, setMobileMenuState } =
    React.useContext(StoreContext)

  const toggleMenu = () => {
    setMobileMenuState()

    if (typeof window !== "undefined" && window.document) {
      window.document.body.classList.toggle("Pacnav--is-active")
      window.document.body.classList.remove("Pacnav--sub-is-active")
    }
  }

  return (
    <li
      className={
        `PacnavToggle is-mobile is-mounted ` +
        (isMobileMenuActive ? "is-active" : "")
      }
      onClick={() => toggleMenu()}
      role="presentation"
      style={{
        zIndex: "99999",
      }}
    >
      <svg width="20px" height="20px" viewBox="0 0 20 20">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill="#000" className="PacnavToggle-lines">
            <rect
              x="0"
              y="4"
              width="20"
              height="2"
              className="PacnavToggle-line1"
            ></rect>
            <rect
              x="0"
              y="9"
              width="20"
              height="2"
              className="PacnavToggle-line2"
            ></rect>
            <rect
              x="0"
              y="9"
              width="20"
              height="2"
              className="PacnavToggle-line3"
            ></rect>
            <rect
              x="0"
              y="14"
              width="20"
              height="2"
              className="PacnavToggle-line4"
            ></rect>
          </g>
        </g>
      </svg>
    </li>
  )
}

export default Hamberger
