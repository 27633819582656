import * as React from "react"
import Logo from "../Logo/Logo"

const LandingPageCheckrSignup = ({ headerLogo }) => {
  return (
    <div className="Wrap">
      <div className="Header-logo">
        <div className="Logo">
          <Logo logo={headerLogo} />
        </div>
      </div>

      <div className={`Header-cta checkr-signup-link`}>
        <a
          href="https://partners.checkr.com/checkr-goodhire/signup"
          className="Button is-default is-small"
        >
          Create Account Now
        </a>
      </div>
    </div>
  )
}

export default LandingPageCheckrSignup
